<div class="by-flex">
  <nz-input-group
    [nzSuffix]="suffixIconSearch"
    [class.button-without-borders]="!showSearchBar"
    nzBorderless
  >
    <input
      #textInput
      type="text"
      nz-input
      byAutofocus
      *ngIf="showSearchBar"
      [@inOutAnimation]
      [placeholder]="'search' | translate | upperfirst"
      [formControl]="control"
      nzBorderless
    />
  </nz-input-group>
  <ng-template #suffixIconSearch>
    <i
      [class.fa-times]="showSearchBar"
      [class.fa-search]="!showSearchBar"
      class="fal pointer search-icon"
      (click)="onToggleSearchBar()"
    ></i>
  </ng-template>
</div>
