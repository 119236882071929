import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import {
  ControlValueAccessor,
  FormBuilder,
  ReactiveFormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutofocusDirective } from '../../directives/autofocus/autofocus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { inOutAnimationFast } from '../../animations/in-out-animation';

@Component({
  selector: 'by-click-to-search',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ClickToSearchComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    NzInputModule,
    NzButtonModule,
    TranslateModule,
    AutofocusDirective,
    ReactiveFormsModule,
    NgUpperFirstPipeModule,
  ],
  templateUrl: './click-to-search.component.html',
  styleUrls: ['./click-to-search.component.scss'],
  animations: [inOutAnimationFast],
})
export class ClickToSearchComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('textInput') textInput: ElementRef<HTMLElement>;

  @Output() textChanged = new EventEmitter<string>();

  private fb = inject(FormBuilder);

  showSearchBar = false;

  control = this.fb.control('');

  subs = new Subscription();

  onChange: (value: string) => void;

  onTouched: () => void;

  onToggleSearchBar() {
    this.showSearchBar = !this.showSearchBar;

    if (this.onTouched) {
      this.onTouched();
    }

    if (!this.showSearchBar) {
      this.control.setValue('');
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.subs.add(
      this.control.valueChanges.subscribe((value) => {
        fn(value);
      }),
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  writeValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
